import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import UserRecordSection from './user_record_section';
import { useState } from 'react';

interface AiTool {
  id: number;
  created_at: string;
}

interface Reflection extends AiTool {
  content: string;
  ai_summary: string;
  reflection_prompt?: any;
  insight_type?: string;
  insight?: string;
}

interface HaikuCheckin extends AiTool {
  content: string;
  ai_poem: string;
}

interface AiToolEntry {
  type: string;
  item: AiTool;
}

export default function Reflections() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , reflections] = useFetchResource<AiToolEntry[]>(
    CoachingApiEndpoints.userReflections(userId),
  );

  const renderToolEntry = (entry: AiToolEntry) => {
    if (entry.type === 'Reflection') {
      return <ReflectionRecord reflection={entry.item as Reflection} />;
    }

    if (entry.type === 'HaikuCheckin') {
      return <HaikuCheckinRecord haikuCheckin={entry.item as HaikuCheckin} />;
    }
  };

  return (
    <UserRecordSection title="User Reflections" loading={loading}>
      <ul className="space-y-3">
        {reflections?.map((reflection) => (
          <li
            key={reflection.item.id + reflection.type}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            {renderToolEntry(reflection)}
          </li>
        ))}
      </ul>
    </UserRecordSection>
  );
}

function HaikuCheckinRecord({ haikuCheckin }: { haikuCheckin: HaikuCheckin }) {
  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-lg">Mood Poem</div>
        <div>
          <HumanReadableDate isoValue={haikuCheckin.created_at} />
        </div>
      </div>

      <div className="mt-2 space-y-3">
        <div className="text-md">{haikuCheckin.content}</div>

        <div className="mt-2 space-y-2 text-sm italic">
          <div className="font-bold">AI Poem</div>
          <div>{haikuCheckin.ai_poem}</div>
        </div>
      </div>
    </div>
  );
}

function ReflectionRecord({ reflection }: { reflection: Reflection }) {
  const [showPrompt, setshowPrompt] = useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-lg">Reflection Journal</div>
        <div>
          <HumanReadableDate isoValue={reflection.created_at} />
        </div>
      </div>

      <div className="mt-2">
        {reflection.reflection_prompt ? (
          <span
            className="text-blue-400 underline text-sm cursor-pointer"
            onClick={() => {
              setshowPrompt(!showPrompt);
            }}>
            {`Prompt ${reflection.reflection_prompt.id}`}
          </span>
        ) : (
          <span className="font-normal text-sm italic"> No Prompt </span>
        )}
      </div>

      {showPrompt && (
        <div className="my-3">
          <div>{reflection.reflection_prompt.title}</div>
          <div>{reflection.reflection_prompt.subtitle}</div>
        </div>
      )}

      <div className="mt-2 space-y-3">
        <div className="text-md">{reflection.content}</div>

        <div className="mt-2 space-y-2 text-sm italic">
          <div className="font-bold">AI Summary</div>
          <div>{reflection.ai_summary}</div>
        </div>

        {reflection.insight_type && (
          <div className="mt-2 text-sm italic space-y-2">
            <div className="font-bold">Insight</div>
            <div>{reflection.insight_type}</div>
            <div>{reflection.insight}</div>
          </div>
        )}
      </div>
    </div>
  );
}
