import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';
import HumanReadableDate from '../human_readable_date';
import { Appointment } from '../../../types/coaching/appointment';
import PastAppointmentRecord from './appointments/past_appointment_record';
import RecordingConsent from './appointments/recording_consent';

export default function Appointments() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loadingApts, , appointments, refetchAppointments] = useFetchResource<
    Appointment[]
  >(CoachingApiEndpoints.userAppointments(userId));

  const pastAppointments = appointments?.filter((apt) => {
    const targetTime = new Date();
    targetTime.setMinutes(targetTime.getMinutes() - 30);
    return (
      apt.scheduled_for < targetTime.toISOString() || apt.user_attended !== null
    );
  });

  const currentAppointments = appointments?.filter((apt) => {
    const minTargetTime = new Date();
    minTargetTime.setMinutes(minTargetTime.getMinutes() - 30);
    const maxTargetTime = new Date();
    maxTargetTime.setMinutes(maxTargetTime.getMinutes() + 30);
    return (
      apt.scheduled_for >= minTargetTime.toISOString() &&
      apt.scheduled_for <= maxTargetTime.toISOString() &&
      apt.user_attended === null
    );
  });

  const upcomingAppointments = appointments?.filter((apt) => {
    const targetTime = new Date();
    targetTime.setMinutes(targetTime.getMinutes() + 30);
    return apt.scheduled_for >= targetTime.toISOString();
  });

  const onAppointmentUpdated = async () => {
    await refetchAppointments();
  };

  return (
    <UserRecordSection title="Appointments" loading={loadingApts}>
      <>
        <div className="py-4 mb-4">
          <RecordingConsent userId={userId} />
        </div>
        <div className="py-4 mb-4">
          <h3 className="text-lg font-bold">Current Appointments</h3>
          <ul className="space-y-3">
            {currentAppointments?.map((appointment, i) => (
              <li
                key={appointment.id}
                className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
                <PastAppointmentRecord
                  appointment={appointment}
                  onAppointmentUpdated={onAppointmentUpdated}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="py-4 mb-4">
          <h3 className="text-lg font-bold">Upcoming Appointments</h3>
          <ul className="space-y-3">
            {upcomingAppointments?.map((appointment, i) => (
              <li
                key={appointment.id}
                className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
                <div>
                  <HumanReadableDate isoValue={appointment.scheduled_for} />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="border-b-2">
          <h3 className="text-lg font-bold">Past Appointments</h3>
          <ul className="space-y-3">
            {pastAppointments?.map((appointment, i) => (
              <li
                key={appointment.id}
                className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
                <PastAppointmentRecord
                  appointment={appointment}
                  onAppointmentUpdated={onAppointmentUpdated}
                />
              </li>
            ))}
          </ul>
        </div>
      </>
    </UserRecordSection>
  );
}
