import {
  postResource,
  useFetchResource,
} from '../../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../../api_endpoints';
import CoachingButton from '../../coaching_button';

interface ConsentData {
  allow_session_recordings?: boolean;
  session_recording_consent_on?: string;
}

export default function RecordingConsent(props: { userId: string }) {
  const [, , consent, refetchConsent] = useFetchResource<ConsentData>(
    CoachingApiEndpoints.userSessionRecordingConsent(props.userId),
  );

  const updateConsent = async (allow: boolean) => {
    const [status] = await postResource(
      CoachingApiEndpoints.userSessionRecordingConsent(props.userId),
      {
        allow_session_recording: allow,
      },
    );

    if (status !== 200) {
      alert('Something went wrong. Please try again later.');
      return;
    }
    refetchConsent();
  };

  return (
    <div>
      {consent?.session_recording_consent_on ? (
        <div className="bg-slate-300 p-3 rounded-md">
          <div>
            {consent.allow_session_recordings
              ? 'User has consented to recordings'
              : 'No recording allowed'}
          </div>
        </div>
      ) : (
        <div className="bg-slate-300 p-3 rounded-md">
          <div>User Session Recording Consent:</div>
          <div className="flex space-x-6 mt-3">
            <CoachingButton title="Allow" onClick={() => updateConsent(true)} />
            <CoachingButton title="Deny" onClick={() => updateConsent(false)} />
          </div>
        </div>
      )}
    </div>
  );
}
