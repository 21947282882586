type IdType = string | number;
type userIdType = IdType;
const coachingBaseApi = '/coaching/v1';
const userScopedApiBase = (userId: string | number) =>
  `${coachingBaseApi}/users/${userId}`;
export const CoachingApiEndpoints = {
  coaches: coachingBaseApi + '/coaches',
  dashboardUsers: coachingBaseApi + '/dashboard_users',
  recommendableBytes: `${coachingBaseApi}/recommendable_bytes`,
  users: (opts?: { all?: boolean }) =>
    coachingBaseApi + '/users' + (opts?.all ? '?all=true' : ''),
  unassignedUsers: coachingBaseApi + '/unassigned_users',
  userAppointments: (userId: userIdType) =>
    userScopedApiBase(userId) + '/appointments',
  userAppointment: (appointmentId: IdType) =>
    coachingBaseApi + `/appointments/${appointmentId}`,
  userAssignedContent: (userId: userIdType) =>
    userScopedApiBase(userId) + '/assigned_bytes',
  userCompletedBytes: (userId: userIdType) =>
    userScopedApiBase(userId) + '/completed_bytes',
  userCheckins: (userId: userIdType) => userScopedApiBase(userId) + '/checkins',
  usersDemographicAssessments: (userId: userIdType) =>
    userScopedApiBase(userId) + '/demographics_assessments',
  usersDassAssessments: (userId: userIdType) =>
    userScopedApiBase(userId) + '/dass_assessments',
  userEwbQuizzes: (userId: userIdType) =>
    userScopedApiBase(userId) + '/ewb_quizzes',
  userIntentions: (userId: userIdType) =>
    userScopedApiBase(userId) + '/intentions',
  userInsuranceCards: (userId: userIdType) =>
    userScopedApiBase(userId) + '/insurance_cards',
  userMemos: (userId: userIdType) => userScopedApiBase(userId) + '/memos',
  userMessageThreads: (userId: userIdType) =>
    userScopedApiBase(userId) + '/message_threads',
  userMessageThread: (userId: userIdType, threadId: userIdType) =>
    userScopedApiBase(userId) + `/message_threads/${threadId}`,
  userMessageThreadMarkRead: (userId: userIdType, threadId: userIdType) =>
    userScopedApiBase(userId) + `/message_threads/${threadId}/mark_read`,
  userMessageThreadArchive: (userId: userIdType, threadId: userIdType) =>
    userScopedApiBase(userId) + `/message_threads/${threadId}/archive`,
  userMessageThreadMessages: (userId: userIdType, threadId: userIdType) =>
    userScopedApiBase(userId) + `/message_threads/${threadId}/messages`,
  userProviderActionItems: (userId: userIdType) =>
    userScopedApiBase(userId) + '/provider_action_items',
  userProgramBytes: (userId: userIdType) =>
    userScopedApiBase(userId) + '/users_program_bytes',
  userOutreachMessages: (userId: userIdType) =>
    userScopedApiBase(userId) + '/outreach_messages',
  userReflections: (userId: userIdType) =>
    userScopedApiBase(userId) + '/reflections',
  user_session_notes: (userId: string) =>
    userScopedApiBase(userId) + '/session_notes',
  user_session_note: (noteId: userIdType) =>
    coachingBaseApi + `/session_notes/${noteId}`,
  userSessionPricing: (userId: userIdType) =>
    userScopedApiBase(userId) + `/session_pricing`,
  userSessionRecordingConsent: (userId: userIdType) =>
    userScopedApiBase(userId) + '/session_recording_consent',
  publish_user_session_note: (noteId: userIdType) =>
    coachingBaseApi + `/session_notes/${noteId}/publish`,
  markProviderActionCompleted: (actionId: userIdType) =>
    coachingBaseApi + `/provider_action_items/${actionId}/complete`,
};

const coachingOpsBase = '/coaching_ops';
export const CoachingOpsApiEndpoints = {
  usersCoaches: (userId?: IdType) =>
    coachingOpsBase + '/users_coaches' + (userId ? `?user_id=${userId}` : ''),
};

const internalBase = '/internal';
export const InternalToolsApiEndpoints = {
  botThreads: (userId: IdType) =>
    internalBase + `/bot_threads?user_id=${userId}`,
  botThread: (id: IdType) => internalBase + `/bot_threads/${id}`,
  botThreadMessages: (threadId: IdType) =>
    internalBase + `/bot_threads/${threadId}/messages`,
  chatBots: internalBase + '/chat_bots',
  chatBot: (id: IdType) => internalBase + `/chat_bots/${id}`,
  partners: internalBase + '/partners',
  user: (userId: IdType) => internalBase + `/users/${userId}`,
};

const cmsBase = '/cms';
export const CmsApiEndpoints = {
  bytes: cmsBase + '/bytes',
  byte: (id: IdType) => cmsBase + `/bytes/${id}`,
  byteGrouping: (groupingId: number) =>
    cmsBase + `/byte_groupings/${groupingId}`,
  domains: cmsBase + '/domains',
  domain: (id: number | string) => cmsBase + `/domains/${id}`,
  domainByteGroupings: (domainId: number | string) =>
    cmsBase + `/domains/${domainId}/byte_groupings`,
  bytesInGroupings: {
    addByte: (groupingId: string | number) =>
      cmsBase + `/byte_groupings/${groupingId}/add_byte`,
    removeByte: (groupingId: string | number, byteId: string | number) =>
      cmsBase + `/byte_groupings/${groupingId}/remove_byte?byte_id=${byteId}`,
  },
};
